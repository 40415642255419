import { Fragment } from "react";

import aws from "assets/img/common/aws-logo.png";

export const banner = {
  bannerTitle: "AI will change",
  rotatingWords: [
    "Healthcare",
    "Finance",
    "Policymaking",
    "Conservation",
    "Defense",
    "Education",
    "Aerospace",
    "Entertainment",
  ],
  bannerSubTitle: "Prepare your students to be responsible ai leaders",
  secondSubTitle: [
    <Fragment>
      Machine Learning Bootcamps with <img src={aws} alt="Aws" /> <br />
      For Community College, HBCU & MSI Faculty
    </Fragment>,
  ],
  thirdSubTitle: <Fragment>Fall 2024 bootcamp:&nbsp; September 29 &ndash; October 12, 2024</Fragment>,
  button: {
    isExternal: false,
    title: "Apply",
    link: "/train-aws-pd-application",
  },
};
